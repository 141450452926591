import { getRequest } from '../requests';
import { decodeJson, jsonOrReject, onRejected } from '../api';
import { getMarketplaceEUBackend } from '../../config/pathURL/config';
import { ApiBillingAddressCodec } from './billingAddress.types';
import { mapBillingAddress } from './billingAddressMapper';
import { BillingAddress } from '../../common/billing/redux/types';

export function fetchBillingAddress(accountId: string): Promise<BillingAddress> {
    return fetch(`${getMarketplaceEUBackend()}/accounts/${accountId}/billing`, getRequest())
        .then(jsonOrReject)
        .then(decodeJson(ApiBillingAddressCodec))
        .then(mapBillingAddress)
        .catch(onRejected([403, 404, 401]));
}
