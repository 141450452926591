import { useGetAccountByIdQuery } from '../../api/accounts/accountApiSlice';
import { useAppSelector } from '../../../../configuration/setup/store';
import { getUserAccount } from '../../../../configuration';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { FormattedMessage } from 'react-intl';
import LoadingErrorState from '../../common/error/LoadingErrorState';
import { isNewAccountApiEnabled } from '../../../../configuration/featureToggle/featureToggleService';
import { BillingAddressContainer } from './BillingAddress';

export const BillingAddressDisplay = () => {
    const userAccount = useAppSelector(getUserAccount);
    const useNewApi = useAppSelector(isNewAccountApiEnabled);

    if (!useNewApi) {
        return <BillingAddressContainer />;
    }

    const { data: account, isLoading, error } = useGetAccountByIdQuery(
        { accountId: userAccount! },
        { skip: !userAccount }
    );

    if (error) {
        return <LoadingErrorState />;
    }

    if (isLoading) {
        return <Spinner text={<FormattedMessage id={'marketplace.billingAddress.loading'} />} isInverse={false} />;
    }

    if (!account) {
        return null;
    }

    const { legalAddress, name, taxId } = account;

    return (
        <address className='BillingAddress'>
            <div>
                <div>{name}!!</div>
                {legalAddress.line1 && <div>{legalAddress.line1}</div>}
                {legalAddress.line2 && <div>{legalAddress.line2}</div>}
                {legalAddress.line3 && <div>{legalAddress.line3}</div>}
                <div>
                    {legalAddress.postalCode} {legalAddress.city}
                </div>
                <div>{legalAddress.countryCode}</div>
            </div>
            <br />
            <div>
                {taxId && (
                    <div>
                        <FormattedMessage id='marketplace.preview.taxNumber' /> {taxId.value}
                    </div>
                )}
            </div>
        </address>
    );
};
