import { DEFAULT_TOOLTIP_DELAY } from '../../../config/config';
import { useIntl } from 'react-intl';
import { OverlayTrigger, Tooltip } from '../../../common/utils/tooltipReExports';
import classnames from 'classnames';
import { IconButtonDefault } from './productCellConfig';
import { ProductInfo } from '../redux/types';

interface Props {
    product: ProductInfo;
}

export const ProductCellExternallyManaged = ({ product }: Props) => {
    const intl = useIntl();

    const overlay = (
        <Tooltip id='tooltip' className={'width-auto max-width-300'}>
            <div>
                {intl.formatMessage({ id: 'marketplace.productExternallyManaged' }, { service: product.productName })}
            </div>
        </Tooltip>
    );

    return (
        <OverlayTrigger
            trigger={OverlayTrigger.TRIGGER_HOVER}
            placement='top'
            overlay={overlay}
            delay={DEFAULT_TOOLTIP_DELAY}
        >
            <span>
                <div className={`btn btn—icon-only ${IconButtonDefault} disabled`}>
                    <span className={classnames('rioglyph', product.booked ? 'rioglyph-ok' : 'rioglyph-lock')} />
                </div>
            </span>
        </OverlayTrigger>
    );
};
