import { RootState } from '../setup/store';
import { NEW_ACCOUNT_API } from './toggles';
import { getUserAccount } from '../login/loginSlice';

export const getFeatureEnabled = (state: RootState, featureName: string): boolean => {
    if (featureName === NEW_ACCOUNT_API) {
        const accountId = getUserAccount(state);
        return accountId === 'a9b5e330-0875-4e25-aaa5-9dd62467edd0';
    }
    return false;
};

export const isProductInMaintenanceMode = (state: RootState) => (productId: string | undefined) => false;

export const isNewAccountApiEnabled = (state: RootState): boolean => 
    getFeatureEnabled(state, NEW_ACCOUNT_API);
