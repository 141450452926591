export const LOCALE_PLACEHOLDER = 'LOCALE';

export function setLocaleForRedirect(url: string, locale: string) {
    return url.replace(LOCALE_PLACEHOLDER, locale.substr(0, 2));
}

export const OPENMARKETPLACE_BASE_URL = `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/`;
export const KOREA_OPENMARKETPLACE_BASE_URL = `https://korea.rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/services`;
export const LATAM_OPENMARKETPLACE_BASE_URL = 'https://americas.rio.cloud/marketplace/';

export const REDIRECT_CONFIG = [
    // Internal test products
    { sku: 'RIO-SKU00000051', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/cypress-connect` },
    { sku: 'RIO-SKU00000058-10', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/cypress-connect` },
    { sku: 'RIO-SKU00000058-20', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/cypress-connect` },
    { sku: 'RIO-SKU00000058-30', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/cypress-connect` },
    { sku: 'RIO-SKU00000058-40', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/cypress-connect` },
    { sku: 'RIO-SKU00000059-10', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/cypress-connect` },
    { sku: 'RIO-SKU00000059-20', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/cypress-connect` },
    { sku: 'RIO-SKU00000059-30', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/cypress-connect` },

    // TODO MANWS-10054: Check what to do with the following products
    { sku: 'MAN-SKU00000048', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/one-minute-locator` },
    { sku: 'RIO-SKU00000045', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/dispatch` },

    // Partner services
    { sku: 'SIXFOLD-SKU00000041', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/partner#c51709` },
    { sku: 'SOLOPLAN-SKU00000036', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/partner#c51736` },
    { sku: 'HEUREMO-SKU00000033', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/partner#c51744` },
    { sku: 'LIS-SKU00000037', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/partner#c51741` },
    { sku: 'DAKO-SKU00000032', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/partner#c51759` },
    { sku: 'TACHOGRAF-SKU00000034', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/partner#c51747` },
    { sku: 'TRANSICS-SKU00000018', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/partner#c51750` },
    { sku: 'VDO-SKU00000016', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/partner#c51753` },
    { sku: 'TRACKS-SKU00000044', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/partner#c51756` },
    {
        sku: 'WABCO-SKU00000030',
        url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/partner/tx-trailerpulse-connect`,
    },
    { sku: 'RIO-DKV-SKU00000031', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/dkv-card` },

    // WORKING
    { sku: 'RIO-SKU00000047', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/geo` },
    { sku: 'RIO-SKU00000047-10', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/geo` },
    { sku: 'RIO-SKU00000047-11', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/geo` },
    { sku: 'RIO-SKU00000047-12', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/geo` },
    { sku: 'MAN-SKU00000005', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/perform` },
    { sku: 'MAN-SKU00000005-10', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/perform` },
    { sku: 'MAN-SKU00000005-11', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/perform` },
    { sku: 'MAN-SKU00000005-12', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/perform` },
    { sku: 'RIO-SKU00000027', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/timed` },
    { sku: 'RIO-SKU00000027-10', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/timed` },
    { sku: 'RIO-SKU00000027-11', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/timed` },
    { sku: 'RIO-SKU00000027-12', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/timed` },
    { sku: 'RIO-SKU00000055', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/geo-data-flow` },
    { sku: 'RIO-SKU00000056', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/timed-data-flow` },
    { sku: 'MAN-SKU00000004-21', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/compliant-m` },
    { sku: 'MAN-SKU00000004-22', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/compliant-m` },
    { sku: 'MAN-SKU00000004-31', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/compliant` },
    { sku: 'MAN-SKU00000066', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/man-simplepay` },
    { sku: 'MAN-SKU00000066-10', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/man-simplepay` },
    { sku: 'MAN-SKU00000081', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/man-simplepay` },
    { sku: 'MAN-SKU00000081-10', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/man-simplepay` },
    { sku: 'MAN-SKU00000049-20', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/man-servicecare-m` },
    { sku: 'MAN-SKU00000049-21', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/man-servicecare-m` },
    { sku: 'MAN-SKU00000049-22', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/man-servicecare-m` },
    { sku: 'MAN-SKU00000049-10', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/man-servicecare-s` },
    { sku: 'MAN-SKU00000050-10', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/man-emanager-s` },
    { sku: 'MAN-SKU00000050-20', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/man-emanager-m` },
    { sku: 'MAN-SKU00000050-21', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/man-emanager-m` },
    { sku: 'MAN-SKU00000050-22', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/man-emanager-m` },
    { sku: 'MAN-SKU00000061', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/man-emanager-m` },
    { sku: 'MAN-SKU00000026', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/man-now` },
    { sku: 'MAN-SKU00000073-10', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/man-datapackage-s` },
    { sku: 'MAN-SKU00000004', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/documents/compliant/dos` },
    { sku: 'MAN-SKU00000004-10', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/documents/compliant/dos` },
    { sku: 'MAN-SKU00000004-11', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/documents/compliant/dos` },
    { sku: 'MAN-SKU00000004-12', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/documents/compliant/dos` },

    // test products - to be updated in case go to production
    {
        sku: 'MAN-SKU00000085',
        url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/man-smartroute`,
    },
    { sku: 'MAN-SKU00000004-32', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/compliant` },
    { sku: 'RIO-SKU00000004', url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/compliant` },
    {
        sku: 'MAN-SKU00000073-20',
        url: `https://rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/produkt/man-datapackage-tigr`,
    },

    // LATAM
    { sku: 'LATAM-SKU00000078', url: LATAM_OPENMARKETPLACE_BASE_URL },
    { sku: 'LATAM-SKU00000079', url: LATAM_OPENMARKETPLACE_BASE_URL },
    { sku: 'LATAM-SKU00000080', url: LATAM_OPENMARKETPLACE_BASE_URL },
    { sku: 'LATAM-SKU00000088', url: LATAM_OPENMARKETPLACE_BASE_URL },
    { sku: 'LATAM-SKU00000089', url: LATAM_OPENMARKETPLACE_BASE_URL },
    { sku: 'LATAM-SKU00000090', url: LATAM_OPENMARKETPLACE_BASE_URL },
    { sku: 'LATAM-SKU00000095', url: LATAM_OPENMARKETPLACE_BASE_URL },

    // KOREA
    {
        sku: 'KOR-SKU00000053',
        url: `https://korea.rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/product/man-servicecare-s`,
        korea: true,
    },
    {
        sku: 'KOR-SKU00000054',
        url: `https://korea.rio.cloud/${LOCALE_PLACEHOLDER}/marketplace/product/perform`,
        korea: true,
    },
];
