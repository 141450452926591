import { useState } from 'react';
import Spinner from '@rio-cloud/rio-uikit/lib/es/Spinner';
import { ServiceOverviewItem } from './types';
import LoadingErrorStateWithReload from '../../common/error/LoadingErrorStateWithReload';
import { OverviewResource } from './redux/types';
import { connect } from 'react-redux';
import { getHasError, getIsLoading } from '../../api/redux/api.redux';
import { ApiCalls } from '../../api/redux/types';
import { isSubscriptionCancelling } from './services/subscriptionService';
import { getPermissions } from '../../common/permissions/redux/permissions.redux';
import { SUBSCRIPTION_WRITE } from '../../common/permissions/permissions';
import { useCheckboxState } from '../../common/utils/checkboxUtil';
import { ProductState } from '../../checkout/resourceSelection/redux/types';
import { ServiceResourceListHeader } from './ServiceResourceListHeader';
import { isTermedLevel } from './services/serviceOverviewLevelService';
import { HIDE_SEARCH_IF_AMOUNT_OF_ITEMS_BELOW_THRESHOLD } from '../../config/config';
import { RootState } from '../../../../configuration/setup/store';
import { ProductType } from '../../common/product/product';
import { ProductTypedFormattedMessage } from '../../common/product/ProductTypedFormattedMessage';
import { ServiceResourceListLevelItem } from './components/ServiceResourceListLevelItem';
import { EmptyResourcesMessage } from './components/EmptyResourcesMessage';
import { isRIO4RentalProductId } from '../../config/rio4rentalProduct';

function LoadingIndicator(props: { productType: ProductType | undefined }) {
    return (
        <div className='padding-15'>
            <Spinner
                text={
                    <ProductTypedFormattedMessage
                        productType={props.productType}
                        assetBasedId={'marketplace.myServices.assets.loading'}
                        userBasedId={'marketplace.myServices.users.loading'}
                    />
                }
                isInverse={false}
            />
        </div>
    );
}

function ResourceListErrorMessage(props: { refetchContent: () => void; productType: ProductType | undefined }) {
    const translationKey =
        props.productType === ProductType.ASSET_BASED
            ? 'marketplace.myServices.assets.fetch.error'
            : 'marketplace.myServices.users.fetch.error';
    return (
        <div className='padding-15'>
            <div className={'ServiceOverviewItemBodyResource-errorMessage form-group'}>
                <LoadingErrorStateWithReload headline={translationKey} onReload={props.refetchContent} />
            </div>
        </div>
    );
}

interface Props {
    service: ServiceOverviewItem;
    resources: Array<OverviewResource> | undefined;
    hasError: boolean;
    isLoading: boolean;
    refetchContent: () => void;
    isCurrentlyProcessingCancellation: (sku: string, resourceId?: string) => boolean;
    hasRightToChangeSubscriptions: boolean;
    selectResource: (resource: OverviewResource) => void;
    selectAllResources: (resources: Array<OverviewResource>, shouldBeSelected: boolean) => void;
    selectedResources: Array<OverviewResource>;
    levels?: Array<string | undefined>;
}

function calculateCanBeModified(
    service: ServiceOverviewItem,
    hasRightToChangeSubscriptions: boolean,
    levels?: Array<string | undefined>
): boolean {
    return Boolean(
        !isRIO4RentalProductId(service.sku) &&
            service.hasRatePlan &&
            hasRightToChangeSubscriptions &&
            (levels?.some((level) => !isTermedLevel(service, level)) ?? true)
    );
}

function ServiceResourceList(props: Props) {
    const {
        service,
        resources,
        hasError,
        isLoading,
        refetchContent,
        selectedResources,
        hasRightToChangeSubscriptions,
        isCurrentlyProcessingCancellation,
        selectAllResources,
        selectResource,
        levels,
    } = props;
    if (hasError) {
        return <ResourceListErrorMessage refetchContent={refetchContent} productType={service.productType} />;
    }
    if (!resources) {
        return <LoadingIndicator productType={service.productType} />;
    }
    if (!resources.length) {
        return isLoading ? (
            <LoadingIndicator productType={service.productType} />
        ) : (
            <EmptyResourcesMessage productType={service.productType} />
        );
    }
    const selectableAssets = resources.filter(
        (resource) => resource.productState === ProductState.ACTIVE && !isTermedLevel(service, resource.targetLevel)
    );
    const hasSelectableResources = selectableAssets.length > 0;
    const isChecked = useCheckboxState(selectableAssets, selectedResources);
    const [searchTerm, setSearchTerm] = useState('');

    const canBeModified = calculateCanBeModified(service, hasRightToChangeSubscriptions, levels);
    const hasTermedLevels = levels?.some((level) => isTermedLevel(service, level)) ?? false;

    const filteredResources = resources.filter(
        (resource) =>
            resource.name.toLowerCase().includes(searchTerm.toLowerCase()) || selectedResources.includes(resource)
    );

    return (
        <div className={'ServiceOverviewItemBodyResource-resourceList row'}>
            <ServiceResourceListHeader
                hasCheckbox={canBeModified && hasSelectableResources}
                selectAllResources={selectAllResources}
                resources={resources.filter((resource) => !isTermedLevel(service, resource.targetLevel))}
                isChecked={isChecked}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                showSearchInput={resources.length >= HIDE_SEARCH_IF_AMOUNT_OF_ITEMS_BELOW_THRESHOLD}
                productType={service.productType || ProductType.ASSET_BASED}
            />
            {filteredResources.length === 0 && (
                <div className={'padding-15'}>
                    <ProductTypedFormattedMessage
                        productType={service.productType}
                        assetBasedId={'marketplace.resourceSelection.asset.noSearchedResourcesFound'}
                        userBasedId={'marketplace.resourceSelection.user.noSearchedResourcesFound'}
                    />
                </div>
            )}
            {(levels ?? [undefined]).map((level) => (
                <ServiceResourceListLevelItem
                    key={level ?? 'single'}
                    level={level}
                    service={service}
                    filteredResources={filteredResources}
                    hasTermedLevels={hasTermedLevels}
                    isCurrentlyProcessingCancellation={isCurrentlyProcessingCancellation}
                    canBeModified={canBeModified}
                    selectResource={selectResource}
                    selectedResources={selectedResources}
                />
            ))}
        </div>
    );
}

const mapStateToProps = (state: RootState) => ({
    hasError: getHasError(state, ApiCalls.SERVICE_RESOURCES) || getHasError(state, ApiCalls.PERMISSIONS),
    isLoading: getIsLoading(state, ApiCalls.SERVICE_RESOURCES) || getIsLoading(state, ApiCalls.PERMISSIONS),
    isCurrentlyProcessingCancellation: (sku: string, resourceId?: string) =>
        isSubscriptionCancelling(state, sku, resourceId),
    hasRightToChangeSubscriptions: getPermissions(state)?.includes(SUBSCRIPTION_WRITE) ?? false,
});

export const ServiceResourceListContainer = connect(mapStateToProps)(ServiceResourceList);
