import { CustomerCenterTab } from '../customerCenter/types';

/**
 * @fileOverview Route definitions for the marketplace module
 */
export const MARKETPLACE_ROUTE_PATH = '';

export const MARKETPLACE_PRODUCT_DETAILS_PATH = 'product';

export const MARKETPLACE_CHECKOUT_PATH = 'checkout';

export const MARKETPLACE_CUSTOMER_CENTER_PATH = 'customerCenter';

export const MARKETPLACE_PAYMENTS_AUTHENTICATION = 'payments/authentication';

export const MARKETPLACE_FREE_TRIAL_BUTTON = 'freeTrialButton';

export const MARKETPLACE_FREE_TRIAL_SECTION_FOR_HOMESCREEN = 'freeTrialSectionHomescreen';

export const MARKETPLACE_START_FREE_TRIAL = 'startFreeTrial';

export const getCustomerCenterPath = (tabName: CustomerCenterTab): string => {
    return `/${MARKETPLACE_CUSTOMER_CENTER_PATH}/${tabName.toLowerCase()}`;
};
