import { getMarketplaceEUBackend } from '../../config/pathURL/config';
import { getRequest } from '../requests';
import { decodeJson, jsonOrReject, onRejected } from '../api';
import { ApiPaymentMethodTypesCodec, PaymentMethodType } from './paymentMethodTypes.types';

export function fetchPaymentMethodTypes(accountId: string): Promise<Array<PaymentMethodType>> {
    return fetch(`${getMarketplaceEUBackend()}/accounts/${accountId}/payment-method-types`, getRequest())
        .then(jsonOrReject)
        .then(decodeJson(ApiPaymentMethodTypesCodec))
        .then((it) => it.payment_method_types)
        .catch(onRejected([403]));
}
