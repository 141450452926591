import { getMarketplaceEUBackend } from '../../config/pathURL/config';
import { getRequest, postRequest } from '../requests';
import { decodeJson, jsonOrReject, onRejected } from '../api';
import { ApiStripePublicKeyCodec, ApiStripeSecretCodec } from './stripe.types';
import { extractPublicKey, extractSecret } from './stripeMapper';

export function fetchStripeApiKey(): Promise<string> {
    return fetch(`${getMarketplaceEUBackend()}/payment-providers/public-key`, getRequest())
        .then(jsonOrReject)
        .then(decodeJson(ApiStripePublicKeyCodec))
        .then(extractPublicKey)
        .catch(onRejected());
}

export function fetchStripeClientSecret(accountId: string): Promise<string> {
    return fetch(`${getMarketplaceEUBackend()}/accounts/${accountId}/payment-secret`, postRequest())
        .then(jsonOrReject)
        .then(decodeJson(ApiStripeSecretCodec))
        .then(extractSecret)
        .catch(onRejected());
}
