import * as t from 'io-ts';

const AddressCodec = t.type({
    line_1: t.union([t.string, t.undefined, t.null]),
    line_2: t.union([t.string, t.undefined, t.null]),
    line_3: t.union([t.string, t.undefined, t.null]),
    city: t.union([t.string, t.undefined, t.null]),
    postal_code: t.union([t.string, t.undefined, t.null]),
    country_code: t.string,
});

const TaxIdCodec = t.type({
    value: t.string,
    tax_type: t.string,
});

const ExternalIdentifierCodec = t.type({
    type: t.string,
    value: t.string,
});

export const AccountResponseCodec = t.type({
    id: t.string,
    name: t.string,
    legal_address: AddressCodec,
    tax_id: t.union([TaxIdCodec, t.undefined, t.null]),
    external_identifiers: t.union([t.array(ExternalIdentifierCodec), t.undefined, t.null]),
    tenant: t.string,
    created_at: t.union([t.string, t.undefined, t.null]),
    finalization_date: t.union([t.string, t.undefined, t.null]),
    logistics_tenant: t.union([t.string, t.undefined, t.null]),
    registration_channel: t.union([t.string, t.undefined, t.null]),
    life_cycle_state: t.union([t.string, t.undefined, t.null]),
    account_type: t.union([t.string, t.undefined, t.null]),
});
